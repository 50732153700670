import React from 'react';
import { AppBar, Toolbar, Button, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import RealtyTagLogo from './RealtyTagLogo';

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <AppBar position="static" color="default" elevation={1} sx={{ mb: 2 }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Box 
          sx={{ 
            cursor: 'pointer', 
            display: 'flex', 
            alignItems: 'center' 
          }} 
          onClick={() => navigate('/')}
        >
          <RealtyTagLogo style={{ height: '40px', marginRight: '10px' }} />
        </Box>

        <Box>
          <Button 
            color="inherit"
            onClick={() => navigate('/')}
            sx={{ 
              mx: 1,
              fontWeight: location.pathname === '/' ? 'bold' : 'normal',
              borderBottom: location.pathname === '/' ? '2px solid #1976d2' : 'none'
            }}
          >
            Home
          </Button>
          <Button 
            color="inherit"
            onClick={() => navigate('/agent')}
            sx={{ 
              mx: 1,
              fontWeight: location.pathname === '/agent' ? 'bold' : 'normal',
              borderBottom: location.pathname === '/agent' ? '2px solid #1976d2' : 'none'
            }}
          >
            Agent Portal
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;