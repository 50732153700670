import React, { useRef, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { CameraAlt, Stop, PhotoCamera, Replay } from '@mui/icons-material';

const CaptureCamera = ({ setPhotoData }) => {
  const videoRef = useRef(null);
  const photoRef = useRef(null);
  const [hasPhoto, setHasPhoto] = useState(false);
  const [cameraError, setCameraError] = useState('');

  const startCamera = async (e) => {
    if (e && e.preventDefault) e.preventDefault(); // Prevent form submission if an event exists

    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      setCameraError('Your browser does not support camera access. Please try a different browser.');
      return;
    }

    try {
      // Request camera access with environment-facing camera
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: { ideal: 'environment' },
          width: { ideal: 1280 },
          height: { ideal: 720 },
        },
      });

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        console.log('Camera started successfully', stream);
      }

      setCameraError(''); // Clear any previous errors if successful
    } catch (err) {
      console.error('Error accessing the camera: ', err);
      setCameraError('Unable to access the camera. Please ensure camera permissions are granted.');
    }
  };

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
      console.log('Camera stopped successfully');
    }
  };

  const takePhoto = (e) => {
    e.preventDefault(); // Prevent form submission on button click

    // Ensure video is available and canvas is rendered
    if (!videoRef.current) {
      setCameraError('Camera not ready.');
      return;
    }

    if (!photoRef.current) {
      setCameraError('Canvas is not available. Please try again.');
      console.error('Canvas is not available when attempting to take photo.');
      return;
    }

    const width = videoRef.current.videoWidth;
    const height = videoRef.current.videoHeight;

    if (!width || !height) {
      console.error('Camera is not ready or not available');
      setCameraError('Camera is not ready. Please try again.');
      return;
    }

    const context = photoRef.current.getContext('2d');
    photoRef.current.width = width;
    photoRef.current.height = height;

    // Draw the video frame onto the canvas
    context.drawImage(videoRef.current, 0, 0, width, height);
    setHasPhoto(true);

    // Capture the photo data URL and save it to state
    const photoDataUrl = photoRef.current.toDataURL('image/png');
    setPhotoData(photoDataUrl);
    console.log('Photo taken successfully and data saved.');

    // Stop the camera after capturing the photo
    stopCamera();
  };

  const retakePhoto = () => {
    setHasPhoto(false);
    // Start the camera again without passing an event
    startCamera();
  };

  return (
    <Box className="camera-container" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 3 }}>
      {/* Camera Video Feed */}
      <Box className="camera">
        <video
          ref={videoRef}
          autoPlay
          playsInline
          muted
          className="camera-video"
          style={{ width: '100%', maxWidth: '640px', borderRadius: '10px', display: hasPhoto ? 'none' : 'block' }}
        />
      </Box>

      {/* Display Captured Photo */}
      <Box className="photo-container" sx={{ mt: 2 }}>
        <canvas
          ref={photoRef}
          className="photo-canvas"
          style={{ width: '100%', maxWidth: '640px', borderRadius: '10px', display: hasPhoto ? 'block' : 'none' }}
        />
      </Box>

      {/* Toolbar for Camera Controls */}
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 1 }}>
        {!hasPhoto && (
          <>
            <Button
              startIcon={<CameraAlt />}
              color="primary"
              variant="contained"
              onClick={startCamera}
            >
              Start Camera
            </Button>
            <Button
              startIcon={<Stop />}
              color="secondary"
              variant="contained"
              onClick={stopCamera}
            >
              Stop Camera
            </Button>
            <Button
              startIcon={<PhotoCamera />}
              color="success"
              variant="contained"
              onClick={takePhoto}
            >
              Take Photo
            </Button>
          </>
        )}
        {hasPhoto && (
          <Button
            startIcon={<Replay />}
            color="default"
            variant="contained"
            onClick={retakePhoto}
          >
            Retake Photo
          </Button>
        )}
      </Box>

      {/* Display Camera Error Messages */}
      {cameraError && (
        <Box className="camera-error" sx={{ color: 'red', mt: 2 }}>
          <Typography variant="body2">{cameraError}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default CaptureCamera;
