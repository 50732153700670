export const downloadQR = (canvas, fileName) => {
    if (!canvas) {
      console.error('Canvas element not found.');
      return;
    }
  
    const scaleFactor = 4;  
    const canvasWidth = canvas.width * scaleFactor;
    const canvasHeight = canvas.height * scaleFactor;
  
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = canvasWidth;
    tempCanvas.height = canvasHeight;
  
    const tempContext = tempCanvas.getContext('2d');
    tempContext.scale(scaleFactor, scaleFactor);  
    tempContext.drawImage(canvas, 0, 0);  
  
    const pngUrl = tempCanvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = `${fileName}.png`;
  
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  