import React from 'react';

const RealtyTagLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 400 75" // Adjusted viewBox for increased size
    width="300" // Increased width for larger logo
    height="75" // Increased height for larger logo
  >
    <text 
      x="0" 
      y="50" 
      fontFamily="Arial, sans-serif" 
      fontSize="60" // Increased font size by 50%
      fontWeight="bold" // Make the font bold for increased thickness
      fill="black"
    >
      Realty
    </text>
    <text 
      x="190" // Adjusted to eliminate space completely
      y="50" 
      fontFamily="Arial, sans-serif" 
      fontSize="60" // Increased font size by 50%
      fontWeight="bold" // Make the font bold for increased thickness
      fill="green"
    >
      Tag
    </text>
  </svg>
);

export default RealtyTagLogo;
