import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Button } from '@mui/material';
import { Launch as LaunchIcon } from '@mui/icons-material';

// Full rooms array
const rooms = ["Home", "Kitchen", "Living", "Dining", "Family", "Primary", "Patio", "Garage", "Bedroom-1", "Bedroom-2", "Bedroom-3", "Laundry", "Contact Info", "Casita", "OnSuite-1", "OnSuite-2", "Den", "Office"];

const QRCodeSection = ({
  submittedLink,
  mainQRCodeRef,
  roomQRCodeRefs,
  handleDownloadMainQR,
  handleDownloadRoomQR
}) => {
  // Function to open property link in new popup window
  const openPropertyLink = () => {
    const windowFeatures = {
      width: Math.min(1200, window.screen.width),
      height: Math.min(800, window.screen.height),
      left: (window.screen.width - Math.min(1200, window.screen.width)) / 2,
      top: (window.screen.height - Math.min(800, window.screen.height)) / 2,
    };

    const featuresStr = `
      width=${windowFeatures.width},
      height=${windowFeatures.height},
      left=${windowFeatures.left},
      top=${windowFeatures.top},
      location=no,
      menubar=no,
      toolbar=no,
      status=no,
      scrollbars=yes,
      resizable=yes
    `.replace(/\s+/g, '');

    const popup = window.open(submittedLink, 'PropertyPreview', featuresStr);
    
    // Ensure popup wasn't blocked
    if (popup === null || typeof popup === 'undefined') {
      alert('Please enable popups to view the property listing.');
    } else {
      popup.focus();
    }
  };
  
  // Function to download the Main, Contact Info, and Home QR codes as a ZIP
  const handleDownloadMainGroupQR = () => {
    const mainCanvas = mainQRCodeRef.current?.querySelector('canvas');
    const contactInfoCanvas = roomQRCodeRefs.current["Contact Info"]?.querySelector('canvas');
    const homeCanvas = roomQRCodeRefs.current["Home"]?.querySelector('canvas');

    if (mainCanvas && contactInfoCanvas && homeCanvas) {
      const zip = new JSZip();
      zip.file("Main_Listing_QR.png", mainCanvas.toDataURL().split(',')[1], { base64: true });
      zip.file("Contact_Info_QR.png", contactInfoCanvas.toDataURL().split(',')[1], { base64: true });
      zip.file("Home_QR.png", homeCanvas.toDataURL().split(',')[1], { base64: true });

      zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, "Main_Contact_Home_QRCodes.zip");
      });
    } else {
      console.error("One or more QR code canvases not found.");
    }
  };

  // Function to download the remaining room QR codes as a ZIP
  const handleDownloadRemainingQR = () => {
    const zip = new JSZip();
    rooms.forEach(room => {
      if (room !== "Home" && room !== "Contact Info") {
        const roomCanvas = roomQRCodeRefs.current[room]?.querySelector('canvas');
        if (roomCanvas) {
          zip.file(`${room}_QR.png`, roomCanvas.toDataURL().split(',')[1], { base64: true });
        }
      }
    });

    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, "Remaining_Rooms_QRCodes.zip");
    });
  };

  return (
    <>
      {submittedLink && (
        <section className="section">
          <div className="property-preview-container">
            <h3>Property Link Preview</h3>
            <Button
              variant="contained"
              color="primary"
              startIcon={<LaunchIcon />}
              onClick={openPropertyLink}
              sx={{ mb: 3 }}
            >
              Preview Property Listing
            </Button>
          </div>
          
          <div className="qr-code-horizontal-container">
            <div className="qr-code-item">
              <h4>Main QR Code</h4>
              <div ref={mainQRCodeRef}>
                <QRCodeCanvas value={submittedLink} size={256} />
              </div>
            </div>
            <div className="qr-code-item">
              <h4>Contact Info QR Code</h4>
              <div ref={(el) => roomQRCodeRefs.current["Contact Info"] = el}>
                <QRCodeCanvas
                  value={`${window.location.origin}/contact-info?submittedLink=${encodeURIComponent(submittedLink)}`}
                  size={256}
                />
              </div>
            </div>
            <div className="qr-code-item">
              <h4>Home QR Code</h4>
              <div ref={(el) => roomQRCodeRefs.current["Home"] = el}>
                <QRCodeCanvas
                  value={`${window.location.origin}/listing?url=${submittedLink}&room=Home`}
                  size={256}
                />
              </div>
            </div>
          </div>
          <div className="button-group">
            <button onClick={handleDownloadMainGroupQR}>Download Main, Contact Info, and Home QR Codes</button>
          </div>
        </section>
      )}

      {submittedLink && (
        <section className="section">
          <h2>QR Codes for Other Rooms</h2>
          <div className="qr-code-room-container">
            {rooms.filter(room => room !== "Home" && room !== "Contact Info").map((room) => {
              const roomQrLink = `${window.location.origin}/listing?url=${submittedLink}&room=${room}`;

              return (
                <div key={room} id={`${room}QR`} className="qr-code-room">
                  <h4>{room} QR Code</h4>
                  <div ref={(el) => roomQRCodeRefs.current[room] = el}>
                    <QRCodeCanvas value={roomQrLink} size={256} />
                  </div>
                  <button onClick={() => handleDownloadRoomQR(room)}>Download {room} QR Code</button>
                </div>
              );
            })}
          </div>
          <button onClick={handleDownloadRemainingQR}>Download All Other Room QR Codes</button>
        </section>
      )}
    </>
  );
};

export default QRCodeSection;