import React, { useState, useRef } from 'react';
import './App.css';
import LeadTable from './LeadTable';
import LogoGrid from './LogoGrid';
import URLForm from './URLForm';
import QRCodeSection from './QRCodeSection';
import { downloadQR } from './utils/downloadQR';

const Home = () => {
  const [submittedLink, setSubmittedLink] = useState('');  
  const [iframeError, setIframeError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  const mainQRCodeRef = useRef(null);
  const roomQRCodeRefs = useRef({});

  const handleIframeError = () => {
    setIframeError(true);
    setErrorMessage('This site does not allow embedding in iframes. Please provide a new URL.');
  };

  const handleDownloadMainQR = () => {
    const mainCanvas = mainQRCodeRef.current?.querySelector('canvas');
    if (mainCanvas) {
      downloadQR(mainCanvas, "Main Listing QR");
    } else {
      console.error('Main QR code canvas not found.');
    }
  };

  const handleDownloadRoomQR = (room) => {
    const roomCanvas = roomQRCodeRefs.current[room]?.querySelector('canvas');
    if (roomCanvas) {
      downloadQR(roomCanvas, `${room} QR`);
    } else {
      console.error(`QR code canvas for ${room} not found.`);
    }
  };

  return (
    <div className="App">
      <header className="header">
        <h1>Welcome to RealtyTag - Real Estate QR Code & Lead Capture</h1>
        <p>Maximize your property listings with QR codes that capture leads and provide home-buying-related coupons.</p>
      </header>

      <div className="floating-box" onClick={() => document.getElementById('propertyLink').focus()}>
        <p>Get QR Codes to Promote your Listing!</p>
        Realtors try for free
      </div>

      <section className="section">
        <h2>For Real Estate Agents - Capture Leads Easily</h2>
        <LeadTable />
      </section>

      <section className="section">
        <h2>For Homebuyers - Get the Best Deals on Your New Home</h2>
        <LogoGrid />
      </section>

      <URLForm setSubmittedLink={setSubmittedLink} />

      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

      {submittedLink && (
        <QRCodeSection
          submittedLink={submittedLink}
          handleIframeError={handleIframeError}
          iframeError={iframeError}
          mainQRCodeRef={mainQRCodeRef}
          roomQRCodeRefs={roomQRCodeRefs}
          handleDownloadMainQR={handleDownloadMainQR}
          handleDownloadRoomQR={handleDownloadRoomQR}
        />
      )}

      <footer className="footer">
        <p>&copy; 2024 RealtyTag | Connect your listings, capture leads, provide value!</p>
      </footer>
    </div>
  );
};

export default Home;