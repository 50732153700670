import { useState } from 'react';
import axios from 'axios';

const useFormHandler = (setSubmittedLink) => {
  const [realtorLink, setRealtorLink] = useState('');
  const [agentName, setAgentName] = useState('');
  const [agentPhone, setAgentPhone] = useState('');
  const [agentEmail, setAgentEmail] = useState(''); // Add state for agentEmail
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Function to format phone number for display (e.g., (123) 456-7890)
  const formatPhoneNumber = (value) => {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, ''); // Remove non-digit characters
    if (phoneNumber.length < 4) return phoneNumber;
    if (phoneNumber.length < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  // Handle phone number change and formatting
  const handlePhoneChange = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setAgentPhone(formattedPhone); // Display the formatted phone number
  };

  // Function to extract only the digits from the formatted phone number
  const extractDigitsFromPhone = (phone) => {
    return phone.replace(/[^\d]/g, ''); // Remove all non-digit characters
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!realtorLink) {
      setErrorMessage('Please provide a valid URL.');
      return;
    }

    if (!agentEmail) {
      setErrorMessage('Please provide a valid email address.');
      return;
    }

    setLoading(true); // Disable the submit button during the request

    try {
      // Post only digits for phone number
      const cleanPhone = extractDigitsFromPhone(agentPhone);

      // Send form data to the correct API endpoint
      const response = await axios.post('https://zuv3enmnc9.execute-api.us-east-1.amazonaws.com/prod/createlisting', {
        url: realtorLink,
        agent: agentName,
        phone: cleanPhone,  // Post the clean phone number (digits only)
        email: agentEmail   // Add the email to the request
      });

      if (response.status === 200) {
        // If successful, set the submitted link for QR code generation and reset the form
        setSubmittedLink(realtorLink);
        setRealtorLink('');  // Reset the form fields
        setAgentName('');
        setAgentPhone('');
        setAgentEmail('');  // Reset the email field
        setErrorMessage('');  // Clear any previous error message
      } else {
        setErrorMessage('Failed to submit. Please try again.');
      }
    } catch (error) {
      setErrorMessage('Failed to submit the form. Please try again.');
      console.error('Error posting to the API:', error);
    } finally {
      setLoading(false); // Re-enable the submit button after completion
    }
  };

  return {
    realtorLink,
    agentName,
    agentPhone,
    agentEmail, // Return agentEmail state
    handleSubmit,
    handlePhoneChange,
    setRealtorLink,
    setAgentName,
    setAgentEmail, // Return setAgentEmail function
    loading,
    errorMessage
  };
};

export default useFormHandler;
