import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Container, TextField, Button, Typography, Card, CardContent, Box, CircularProgress } from '@mui/material';
import QRCodeSection from './QRCodeSection';
import { downloadQR } from './utils/downloadQR';

const Agent = () => {
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [agentData, setAgentData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedListing, setSelectedListing] = useState(null);
  
  // Refs for QR code canvases
  const mainQRCodeRef = useRef(null);
  const roomQRCodeRefs = useRef({});

  // Phone number formatting and validation
  const formatPhoneNumber = (value) => {
    const numericValue = value.replace(/\D/g, '');
    
    if (numericValue.length <= 3) {
      return numericValue;
    } else if (numericValue.length <= 6) {
      return `(${numericValue.slice(0, 3)})${numericValue.slice(3)}`;
    } else {
      return `(${numericValue.slice(0, 3)})${numericValue.slice(3, 6)}-${numericValue.slice(6, 10)}`;
    }
  };

  const validatePhoneNumber = (phoneNumber) => {
    const numericPhone = phoneNumber.replace(/\D/g, '');
    
    if (numericPhone.length !== 10) {
      setPhoneError('Phone number must be 10 digits');
      return false;
    }
    
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (!phoneRegex.test(phoneNumber)) {
      setPhoneError('Please enter a valid phone number');
      return false;
    }

    setPhoneError('');
    return true;
  };

  const handlePhoneChange = (e) => {
    const formattedNumber = formatPhoneNumber(e.target.value);
    if (formattedNumber.length <= 14) {
      setPhone(formattedNumber);
      validatePhoneNumber(formattedNumber);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validatePhoneNumber(phone)) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.get(`https://w9xkmalp9l.execute-api.us-east-1.amazonaws.com/prod/agent/${phone.replace(/\D/g, '')}`);
      setAgentData(response.data);
      setSelectedListing(null);
    } catch (error) {
      console.error('Error fetching agent data:', error);
      setAgentData(null);
      setPhoneError('No agent found with this phone number');
    } finally {
      setIsLoading(false);
    }
  };

  const handleListingSelect = (listing) => {
    setSelectedListing(listing.Url);
  };

  // QR code download handlers
  const handleDownloadMainQR = () => {
    const mainCanvas = mainQRCodeRef.current?.querySelector('canvas');
    if (mainCanvas) {
      downloadQR(mainCanvas, "Main Listing QR");
    } else {
      console.error('Main QR code canvas not found.');
    }
  };

  const handleDownloadRoomQR = (room) => {
    const roomCanvas = roomQRCodeRefs.current[room]?.querySelector('canvas');
    if (roomCanvas) {
      downloadQR(roomCanvas, `${room} QR`);
    } else {
      console.error(`QR code canvas for ${room} not found.`);
    }
  };

  const formatDate = (isoDate) => {
    return new Date(isoDate).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4 }}>
        Agent QR Code Generator
      </Typography>

      <Box component="form" onSubmit={handleSubmit} sx={{ mb: 4 }}>
        <TextField
          fullWidth
          label="Phone Number"
          variant="outlined"
          value={phone}
          onChange={handlePhoneChange}
          error={!!phoneError}
          helperText={phoneError}
          placeholder="(XXX)XXX-XXXX"
          sx={{ mb: 2 }}
        />
        <Button 
          type="submit" 
          variant="contained" 
          color="primary" 
          fullWidth
          disabled={isLoading || !!phoneError}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Look Up Agent'}
        </Button>
      </Box>

      {agentData && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            Welcome, {agentData.Agent}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Email: {agentData.Email}
          </Typography>
          
          <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
            Your Listings
          </Typography>
          
          {agentData.listings.map((listing, index) => (
            <Card 
              key={index} 
              sx={{ 
                mb: 2, 
                cursor: 'pointer',
                border: selectedListing === listing.Url ? '2px solid #1976d2' : 'none'
              }}
              onClick={() => handleListingSelect(listing)}
            >
              <CardContent>
                <Typography variant="subtitle1">
                  Submitted: {formatDate(listing.Submitted)}
                </Typography>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    wordBreak: 'break-all',
                    color: 'text.secondary' 
                  }}
                >
                  {listing.Url}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      )}

      {selectedListing && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            QR Codes for Selected Listing
          </Typography>
          <QRCodeSection 
            submittedLink={selectedListing}
            mainQRCodeRef={mainQRCodeRef}
            roomQRCodeRefs={roomQRCodeRefs}
            handleDownloadMainQR={handleDownloadMainQR}
            handleDownloadRoomQR={handleDownloadRoomQR}
          />
        </Box>
      )}
    </Container>
  );
};

export default Agent;