import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Button, TextField, RadioGroup, FormControlLabel, Radio, Grid, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Facebook as FacebookIcon, GetApp as DownloadIcon } from '@mui/icons-material';
import RealtyTagLogo from './RealtyTagLogo';
import CaptureCamera from './CaptureCamera';

const Listing = () => {
  const query = new URLSearchParams(useLocation().search);
  const url = query.get('url');
  const room = query.get('room') || 'Home';

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [phone, setPhone] = useState('');
  const [photoData, setPhotoData] = useState(null); // State to store captured photo data
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [isLoadingVendors, setIsLoadingVendors] = useState(false);

  const getCookie = (cookieName) => {
    const name = `${cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return '';
  };

  useEffect(() => {
    // Retrieve phone number from cookie and set it to state
    const savedPhone = getCookie('phone');
    if (savedPhone) {
      setPhone(savedPhone);
    }

    // Lazy load vendor data for the room
    const fetchVendors = async () => {
      setIsLoadingVendors(true);
      try {
        const response = await axios.post('https://w9xkmalp9l.execute-api.us-east-1.amazonaws.com/prod/savings', {
          Room: room
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (Array.isArray(response.data)) {
          setVendors(response.data);
        } else {
          console.warn('Unexpected response format, setting vendors to empty array.');
          setVendors([]);
        }
      } catch (error) {
        console.error('Error fetching vendors:', error);
        setVendors([]);
      } finally {
        setIsLoadingVendors(false);
      }
    };

    fetchVendors();
  }, [room]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const data = {
      rating,
      comment,
      phone,
      room,
      url,
      photo: photoData // Include the captured photo data in the submission
    };
    try {
      const response = await axios.post('https://w9xkmalp9l.execute-api.us-east-1.amazonaws.com/prod/ratings', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('Rating submitted successfully:', response.data);
      alert('Thank you for your feedback!');
    } catch (error) {
      console.error('Error submitting rating:', error);
      alert('Failed to submit your feedback. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const shareOnFacebook = () => {
    // Share URL
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    window.open(shareUrl, '_blank');
  };

  const downloadPhoto = () => {
    if (!photoData) {
      alert('No photo available to download.');
      return;
    }

    // Create a temporary anchor tag to download the photo
    const link = document.createElement('a');
    link.href = photoData;
    link.download = 'photo.png';
    link.click();
  };

  return (
    <>
      <Helmet>
        <title>{`${room} Coupons and Feedback | RealtyTag`}</title>
        <meta name="description" content={`Rate and review the ${room}. Get exclusive coupons for home improvement.`} />
      </Helmet>

      <Container maxWidth="sm">
        <RealtyTagLogo />
        <Typography variant="h4" gutterBottom>
          Rate this {room}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6">Rate this room:</Typography>
              <RadioGroup
                value={rating}
                onChange={(e) => setRating(parseInt(e.target.value))}
                row
              >
                {[1, 2, 3, 4, 5].map((star) => (
                  <FormControlLabel
                    key={star}
                    value={star}
                    control={<Radio />}
                    label={`${star} Star${star > 1 ? 's' : ''}`}
                  />
                ))}
              </RadioGroup>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Leave a comment"
                variant="outlined"
                multiline
                rows={4}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Enter your feedback"
                fullWidth
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Phone Number (to receive a coupon)"
                variant="outlined"
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Enter your phone number"
                fullWidth
                required
              />
            </Grid>

            {/* Include the Capture Camera Component */}
            <Grid item xs={12}>
              <CaptureCamera setPhotoData={setPhotoData} />
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Submit Review'}
              </Button>
            </Grid>
          </Grid>
        </form>

        <div style={{ marginTop: '20px' }}>
          <Typography variant="h6" gutterBottom>
            Share this listing:
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<FacebookIcon />}
            onClick={shareOnFacebook}
          >
            Share on Facebook
          </Button>
         
        </div>

        <div style={{ marginTop: '30px' }}>
          <Typography variant="h5" gutterBottom>
            Available Coupons for {room}:
          </Typography>
          {isLoadingVendors ? (
            <Typography>Loading coupons...</Typography>
          ) : vendors.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Vendor</TableCell>
                    <TableCell>Link</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vendors.map((vendor) => (
                    <TableRow key={vendor._id}>
                      <TableCell>{vendor.Vendor}</TableCell>
                      <TableCell>
                        <Link href={vendor.Link} target="_blank" rel="noopener noreferrer">
                          {vendor.Vendor}
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No coupons available for this room.</Typography>
          )}
        </div>
      </Container>
    </>
  );
};

export default Listing;
