import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Container, TextField, Button, Grid } from '@mui/material';
import './App.css';

const ContactInfo = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const submittedLink = queryParams.get('submittedLink');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    console.log('Submitted Link:', submittedLink);
  }, [submittedLink]);

  const openPropertyLink = () => {
    // Check if device is mobile
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      // On mobile, open in a new tab
      window.open(submittedLink, '_blank');
    } else {
      // On desktop, open in a popup window
      const windowFeatures = {
        width: Math.min(1200, window.screen.width),
        height: Math.min(800, window.screen.height),
        left: (window.screen.width - Math.min(1200, window.screen.width)) / 2,
        top: (window.screen.height - Math.min(800, window.screen.height)) / 2,
      };

      const featuresStr = `
        width=${windowFeatures.width},
        height=${windowFeatures.height},
        left=${windowFeatures.left},
        top=${windowFeatures.top},
        location=no,
        menubar=no,
        toolbar=no,
        status=no,
        scrollbars=yes,
        resizable=yes
      `.replace(/\s+/g, '');

      const popup = window.open(submittedLink, 'PropertyPreview', featuresStr);
      
      if (popup === null || typeof popup === 'undefined') {
        // If popup is blocked, fall back to new tab
        window.open(submittedLink, '_blank');
      } else {
        popup.focus();
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const data = {
      firstName,
      lastName,
      phone,
      email,
      url: submittedLink,
    };
    try {
      const response = await axios.post('https://w9xkmalp9l.execute-api.us-east-1.amazonaws.com/prod/contact', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('Form submitted successfully:', response.data);

      // Set the phone number in a cookie
      document.cookie = `phone=${phone}; path=/; max-age=31536000`; // Expires in 1 year

      // Open the property link after successful submission
      openPropertyLink();
      
      // Clear form
      setFirstName('');
      setLastName('');
      setPhone('');
      setEmail('');
      
    } catch (error) {
      console.error('Error submitting the form:', error);
      alert('There was an error submitting your information. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container className="App" maxWidth="sm">
      <h1>RealtyTag</h1>
      
      <h2>Contact Information</h2>
      <form onSubmit={handleSubmit} className="form-section">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="First Name"
              variant="outlined"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Last Name"
              variant="outlined"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Phone"
              variant="outlined"
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="URL"
              variant="outlined"
              value={submittedLink}
              InputProps={{
                readOnly: true,
              }}
            />
            <Button 
              type="submit" 
              variant="contained" 
              color="primary" 
              fullWidth 
              disabled={isSubmitting}
              sx={{ mt: 2 }}
            >
              {isSubmitting ? 'Submitting...' : 'View Property'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default ContactInfo;