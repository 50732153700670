import React, { useEffect, useState } from 'react';
import axios from 'axios';

const LogoGrid = () => {
  const [logos, setLogos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // API URL to fetch data
    const apiUrl = 'https://w9xkmalp9l.execute-api.us-east-1.amazonaws.com/prod/savings/home';

    // Using Axios to fetch the data from the API
    axios.get(apiUrl)
      .then((response) => {
        setLogos(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p>Loading logos...</p>;
  }

  if (error) {
    return <p>Error loading logos: {error.message}</p>;
  }

  if (logos.length === 0) {
    return <p>No logos available.</p>;
  }

  return (
      
    <div className="logo-cards-container">
      
      {logos.map((logo) => (
        <div key={logo._id} className="logo-card">
          <a href={logo.Link} target="_blank" rel="noopener noreferrer">
            <img src={logo.Image} alt={`${logo.Vendor} - ${logo.Room}`} className="logo-image" />
          </a>
        </div>
      ))}
    </div>
  );
};

export default LogoGrid;
